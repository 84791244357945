import React from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import FooterTop from '../Sezioni/FooterTop'
import FooterDown from '../Sezioni/FooterDown'
import img1 from "../assets/img/accompagnatori.jpg";
import InputGimlet from '../components/InputGimlet'
import InputBilo from '../components/InputBilo'

function Alimentazione() {
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"L’ALIMENTAZIONE NELL’ESCURSIONISMO"}
                img={img1}
                position={"center"}
            />
            <div className="bg-white relative z-40 p-8 pt-20 flex flex-col">
                <div
                    className="flex flex-row items-center gap-1"
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    <div>I nostri trek {">"}</div>
                    <div>Preparati al tuo trek {">"}</div>
                    <div className="font-bold">L’ALIMENTAZIONE NELL’ESCURSIONISMO</div>
                </div>

                <InputGimlet
                    id='main'
                    title={'L’ALIMENTAZIONE NELL’ESCURSIONISMO'}
                    fontWeight={500}
                    fontStyle="italic"
                    className="my-6 uppercase text-4xl text-[#DF5B23]"
                />

                <div className="mb-5 text-xl">
                    <InputBilo
                        title={'La corretta alimentazione è un fattore determinante per la salute in generale e, a maggior ragione, per chi pratica una regolare attività sportiva.'}
                        fontWeight={300}
                    />
                    <InputBilo
                        title={'Un‘alimentazione corretta prevede una dieta che sia:'}
                        fontWeight={300}
                    />
                    <ul>
                        {[
                            'sana,| composta cioè da ingredienti freschi e genuini',
                            'bilanciata o equilibrata,| nel senso che i vari macro e micronutrienti sono presenti in proporzioni adeguate',
                            'giusta| nel dosaggio delle porzioni, che devono essere né troppo scarse né troppo abbondanti',
                            'corretta| dal punto di vista dell\'apporto calorico, che deve essere adeguato alle caratteristiche strutturali (peso, altezza) e all’attività fisica svolta dall’individuo',
                        ].map((item, idx) => (
                            <li key={idx} className="!list-disc ml-8">
                                <InputBilo title={item.split('|')[0]} fontWeight={700} className={'inline'} />
                                <InputBilo title={item.split('|')[1]} fontWeight={300} className={'inline'} />
                            </li>
                        ))}
                    </ul>
                    <br />
                    <InputBilo
                        title={'L’'}
                        fontWeight={300}
                        className={'inline'}
                    />
                    <InputBilo
                        title={'acqua '}
                        fontWeight={700}
                        className={'inline'}
                    />
                    <InputBilo
                        title={'è la principale sostanza di cui siamo composti e quindi un corretto approvvigionamento è fondamentale.'}
                        fontWeight={300}
                        className={'inline'}
                    />
                    <InputBilo
                        title={'È importante, per chi cammina, curare sempre l’idratazione, anche quando si va in quota e al freddo, dove ci si disidrata prima senza rendersene conto.'}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'Ricordiamo che durante uno sforzo fisico si perde una gran parte di acqua non solo sudando, ma anche tramite la respirazione ed è indispensabile reintegrare quanto perso: quando compare lo stimolo della sete è già tardi. Indicativamente conviene portare da uno a due litri d\'acqua.'}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'Per un corretto funzionamento del nostro organismo abbiamo bisogno di ingerire, nelle dovute quantità e proporzioni:'}
                        fontWeight={300}
                    />
                    <ul>
                        {[
                            'Proteine (formano le fibre muscolari)',
                            'Grassi o lipidi (per le riserve energetiche “a lunga scadenza”)',
                            'Zuccheri e carboidrati| (per le riserve energetiche “a corta scadenza”), principale fonte di energia rapida per i nostri muscoli',
                            'Vitamine e sali minerali (per il corretto funzionamento del metabolismo)',
                        ].map((item, idx) => (
                            item?.includes('|') ?
                            <li key={idx} className="!list-disc ml-8">
                                <InputBilo title={item.split('|')[0]} fontWeight={700} className={'inline'} />
                                <InputBilo title={item.split('|')[1]} fontWeight={300} className={'inline'} />
                            </li>
                            :
                            <li key={idx} className="!list-disc ml-8">
                                <InputBilo title={item} fontWeight={300} />
                            </li>
                        ))}
                    </ul>
                    <br />
                    <InputBilo
                        title={'Il cibo da portare in escursione deve essere in quantità sufficiente e fornire scorte energetiche (barrette energetiche, frutta fresca, frutta secca, …) con le seguenti caratteristiche:'}
                        fontWeight={300}
                    />
                    <ul>
                        {[
                            'facilmente digeribili',
                            'più leggere possibili (dato che vanno nello zaino)',
                            'devono togliere il senso di fame',
                        ].map((item, idx) => (
                            <li key={idx} className="!list-disc ml-8">
                                <InputBilo title={item} fontWeight={300} />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    )
}

export default Alimentazione
