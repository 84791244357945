import React from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import FooterTop from '../Sezioni/FooterTop'
import FooterDown from '../Sezioni/FooterDown'
import img1 from "../assets/img/accompagnatori.jpg";
import InputGimlet from '../components/InputGimlet'
import InputBilo from '../components/InputBilo'

function Allenamento() {
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Allenamento"}
                img={img1}
                position={"center"}
            />
            <div className="bg-white relative z-40 p-8 pt-20 flex flex-col">
                <div
                    className="flex flex-row items-center gap-1"
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    <div>I nostri trek {">"}</div>
                    <div>Preparati al tuo trek {">"}</div>
                    <div className="font-bold">Allenamento</div>
                </div>

                <InputGimlet
                    id='main'
                    title={'allenamento'}
                    fontWeight={500}
                    fontStyle="italic"
                    className="my-6 uppercase text-4xl text-[#DF5B23]"
                />

                <div className="mb-5 text-xl">
                    <InputBilo
                        title={'Trekking e Allenamento'}
                        fontWeight={700}
                        className={'text-[#124761] text-2xl'}
                    />
                    <InputBilo
                        title={'Lo scopo dell’allenamento nel trekking è quello di permettere al nostro organismo di affrontare situazioni di fatica, senza andare incontro a particolari disagi che possono diventare momenti pericolosi. Allenamento vuol dire anche '}
                        fontWeight={300}
                        className={'inline'}
                    />
                    <InputBilo
                        title={'sicurezza, '}
                        fontWeight={700}
                        className={'inline'}
                    />
                    <InputBilo
                        title={'in quanto soprattutto nella fase finale di un\'escursione, la stanchezza può portare a commettere errori banali.'}
                        fontWeight={300}
                        className={'inline'}
                    />
                    <br />
                    <br />
                    <InputBilo
                        title={'Il movimento deve essere svolto, durante la settimana, con costanza e regolarità. Gli allenamenti continuativi danno risultati più validi di quelli saltuari e pesanti.'}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'Se non hai mai effettuato un trekking e non sei abituato a camminare in salita, ti invitiamo ad iniziare la tua esperienza a partire da trek di mezza giornata o giornalieri di difficoltà facile o al massimo facile-medio, per poi passare gradualmente a quelli di difficoltà e durata maggiore.'}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'Inoltre puoi effettuare un allenamento specifico. Inizialmente brevi percorsi con zaino leggero; progressivamente aumentare:'}
                        fontWeight={300}
                    />
                    <ul>
                        {[
                            'il peso dello zaino;',
                            'la lunghezza del percorso;',
                            'il dislivello affrontato.'
                        ].map((item, idx) => (
                            <li key={idx} className="!list-disc ml-8">
                                <InputBilo title={item} fontWeight={300} />
                            </li>
                        ))}
                    </ul>
                    <br />
                    <InputBilo
                        title={'Per trek impegnativi nei termini di lunghezza e dislivello occorre:'}
                        fontWeight={300}
                    />
                    <ul>
                        {[
                            'aumentare la quantità, l’intensità e la durata delle sedute di allenamento durante la settimana;',
                            'alzare gradualmente il livello dell’escursione nel weekend sia in termini di lunghezza che di dislivello;',
                            'rispettare i tempi di recupero che non sono mai uguali per tutti;',
                            'cominciare un allenamento adeguato almeno tre mesi prima.',
                        ].map((item, idx) => (
                            <li key={idx} className="!list-disc ml-8">
                                <InputBilo title={item} fontWeight={300} />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    )
}

export default Allenamento
