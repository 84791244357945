import React from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import FooterTop from '../Sezioni/FooterTop'
import FooterDown from '../Sezioni/FooterDown'
import img1 from "../assets/img/accompagnatori.jpg";
import InputGimlet from '../components/InputGimlet'
import InputBilo from '../components/InputBilo'

function Abbigliamento() {
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Abbigliamento"}
                img={img1}
                position={"center"}
            />
            <div className="bg-white relative z-40 p-8 pt-20 flex flex-col">
                <div
                    className="flex flex-row items-center gap-1"
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    <div>I nostri trek {">"}</div>
                    <div>Preparati al tuo trek {">"}</div>
                    <div className="font-bold">Abbigliamento</div>
                </div>

                <InputGimlet
                    id='main'
                    title={'abbigliamento'}
                    fontWeight={500}
                    fontStyle="italic"
                    className="my-6 uppercase text-4xl text-[#DF5B23]"
                />

                <div className="mb-5 text-xl">
                    <InputBilo
                        title={'Per un trek ci si veste “a cipolla”, ovvero a strati e in base alla stagione per assecondare le variazioni di temperatura, sia esterna che corporea. '}
                        fontWeight={300}
                        className={'inline'}
                    />
                    <InputBilo
                        title={'Traspirabilità, leggerezza, protezione'}
                        fontWeight={700}
                        className={'inline'}
                    />
                    <InputBilo
                        title={' (da acqua e vento) sono le caratteristiche essenziali. '}
                        fontWeight={300}
                        className={'inline'}
                    />
                     
                    <InputBilo
                        title={'Gli strati devono essere tutti abbastanza leggeri, ma - indossati uno sopra l’altro - devono evitare le dispersioni di calore:'}
                        fontWeight={300}
                        className={'inline'}
                    />
                    <ul>
                        <li className='!list-disc ml-8'>
                            <InputBilo
                                title={'lo '}
                                fontWeight={300}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'strato interno (intimo) '}
                                fontWeight={700}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'è quello a contatto con la pelle e deve fare in modo da allontanare il sudore dalla pelle;'}
                                fontWeight={300}
                                className={'inline'}
                            />
                        </li>
                        <li className='!list-disc ml-8'>
                            <InputBilo
                                title={'lo '}
                                fontWeight={300}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'strato intermedio (isolante) '}
                                fontWeight={700}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'trattiene il calore corporeo per proteggerci dal freddo;'}
                                fontWeight={300}
                                className={'inline'}
                            />
                        </li>
                        <li className='!list-disc ml-8'>
                            <InputBilo
                                title={'lo '}
                                fontWeight={300}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'strato più esterno (protettivo o guscio) '}
                                fontWeight={700}
                                className={'inline'}
                            />
                            <InputBilo
                                title={'deve proteggerci dal vento e dalla pioggia.'}
                                fontWeight={300}
                                className={'inline'}
                            />
                        </li>
                    </ul>
                    <br />
                    <InputBilo
                        title={'Consigliamo:'}
                        fontWeight={300}
                    />
                    <ul>
                        {[
                            'magliette tecniche traspiranti',
                            'pile',
                            'pantaloni comodi in materiale impermeabile e traspirante',
                            'guscio (meglio in Gore-Tex®) in qualsiasi stagione (in caso di percorsi in piano, è possibile utilizzare la mantellina impermeabile)',
                            'borraccia o camel bag, thermos in inverno',
                            'in inverno: berretto, guanti e scaldacollo',
                            'in estate: cappello da sole',
                            'occhiali da sole, indispensabili in tutte le stagioni',
                            'ghette (utili anche con la pioggia o su sentiero fangoso, non solo quando si prevede di camminare sulla neve)',
                        ].map((item, idx) => (
                            <li key={idx} className='!list-disc ml-8'>
                                <InputBilo title={item} fontWeight={300} />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    )
}

export default Abbigliamento
