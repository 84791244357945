import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/img/Logo.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import lente from "../assets/img/icona-lente-header.png";
import TextField from "@mui/material/TextField";
import vicinoAme from "../assets/img/icona-geolocal-header.png";
import areaRiservata from "../assets/img/icona-area riservata-header.png";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { connect } from "react-redux";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import env from "../conf/variables";
import DialogCustom from "./DialogCustom";
import InputBilo from "./InputBilo";
import { useFormik } from "formik";
import InputCustom from "./InputCustom";

const TopbarMobile = ({ dispatch, setDrawerIsOpen = () => { } }) => {
    const navigate = useNavigate();
    const location = useLocation()
    const [errorAssoc, setErrorAssoc] = React.useState({
        open: false,
        error: "",
    });
    const [openRinnova, setOpenRinnova] = React.useState(false);
    const [assocType, setAssocType] = React.useState([]);
    const [errorAssocValue, setErrorAssocValue] = React.useState(false);
    const [openPaymentMethod, setOpenPaymentMethod] = React.useState(false);
    const [today, setToday] = React.useState(new Date())
    const [rinnovaDate, setRinnovaDate] = React.useState(new Date())
    const count = useSelector(state => state.count)
    const [showSezioni, setShowSezioni] = React.useState(false);
    const [showPages, setShowPages] = React.useState([]);
    const [showPagesPages, setShowPagesPages] = React.useState([]);
    const [showTrek, setShowTrek] = React.useState(false);
    const [showAss, setShowAss] = React.useState(false);
    const [showTrekkingItalia, setShowTrekkingItalia] = React.useState(false);
    const [showProgetti, setShowProgetti] = React.useState(false);
    const [showClassificazione, setShowClassificazione] = React.useState(false);
    const [showPreparati, setShowPreparati] = React.useState(false)
    const [showNazionaliAssoc, setShowNazionaliAssoc] = React.useState([]);
    const [showNazionaliTrek, setShowNazionaliTrek] = React.useState([]);
    const [isIphone, setIsIphone] = React.useState(false)
    const [linkForIphone, setLinkForIphone] = React.useState(null)

    const [search, setSearch] = React.useState("");
    const [minCaratteri, setMinCaratteri] = React.useState(false)
    const [state, setState] = React.useState({
        left: false,
    });
    const [anchorEl3, setAnchorEl3] = React.useState(null);
    const open3 = Boolean(anchorEl3);

    const fetcher = (url) => api.get(url).then((res) => res);
    const { data: departments } = useSWR([`/all-departments`], fetcher);

    const fetcher2 = (url) => api.get(url).then((res) => res);
    const { data: listPages } = useSWR([`/section/list`], fetcher2);

    const fetcher4 = (url) => api.get(url).then((res) => res);
    const { data: carta } = useSWR([`/users/${count && count.profile && count.profile.id}/carta`], fetcher4);

    function createPagesArray(department) {
        let pagesArray = department ? department.map((page, inddex) => {
            return {
                name: page.title,
                to: '/' + page.id,
                pages: page.children.length > 0 ? createPagesArray(page.children) : []
            }
        }) : []
        return pagesArray
    }

    React.useEffect(() => {
        setShowPages(prevState => {
            let anchors = prevState;
            departments && departments.data && departments.data.data
                ? (anchors = departments.data.data.map((department, index) => {
                    return {
                        name: department.name.includes("Sezione")
                            ? department.name.substring(8)
                            : department.name,
                        active: false,
                    };
                }))
                : (anchors = prevState);
            return anchors;
        })
        setShowPagesPages(prevState => {
            let anchors = prevState;
            departments && departments.data && departments.data.data
                ? (anchors = departments.data.data.map((department, index) => {
                    if (department.name === 'Nazionale') {
                        return {
                            name: '',
                            active: false
                        }
                    }
                    let pages = createPagesArray(listPages && listPages.data && listPages.data.data && listPages.data.data[department.id] ? listPages.data.data[department.id] : [])
                    return pages.map((page, index) => {
                        return {
                            name: page.name,
                            active: false,
                        };
                    })
                }))
                : (anchors = prevState);
            anchors.shift() //elimina 'Nazionale'
            return anchors;
        })
        setShowNazionaliAssoc((prevState) => {
            let anchors = prevState;
            listPages && listPages.data && listPages.data.data && listPages.data.data[1] && listPages.data.data[1].associazione
                ? (anchors = listPages.data.data[1].associazione.map((pageAssoc, index) => {
                    return {
                        name: pageAssoc.title,
                        active: false,
                    };
                }))
                : (anchors = prevState);
            return anchors;
        })
        setShowNazionaliTrek((prevState) => {
            let anchors = prevState;
            listPages && listPages.data && listPages.data.data && listPages.data.data[1] && listPages.data.data[1].trek
                ? (anchors = listPages.data.data[1].trek.map((pageTrek, index) => {
                    return {
                        name: pageTrek.title,
                        active: false,
                    };
                }))
                : (anchors = prevState);
            return anchors;
        })
    }, [departments])

    function changedShowPages(name) {
        setShowPages(prevState => {
            let anchors = prevState;
            let newAnchors = anchors.map((anchor) => {
                if (anchor.name === name) {
                    anchor.active = !anchor.active
                } else if (anchor.name !== name && anchor.active) {
                    anchor.active = false
                }
                return anchor
            })
            return newAnchors
        })
    }
    function changedShowPagesPages(name, indexDep) {
        setShowPagesPages(prevState => {
            let depart = prevState;
            let newValue = depart.map((dep, index) => {
                if (index === indexDep) {
                    let newAnchors = dep.map((pages) => {
                        if (pages.name === name) {
                            pages.active = !pages.active
                        } else if (pages.name !== name && pages.active) {
                            pages.active = false
                        }
                        return pages
                    })
                    return newAnchors
                }
                return dep
            })
            return newValue
        })
    }

    React.useEffect(() => {
        const date = departments && departments.data && departments.data.data && departments.data.data.length && departments.data.data[0]
        const day = Number(date?.open_membership_day)
        const month = Number(date?.open_membership_month)
        const year = Number(today?.getFullYear())

        if (date) {
            setRinnovaDate(new Date(`${month > 9 ? month : `0${month}`}/${day > 9 ? day : `0${day}`}/${year}`))
        }

    }, [departments && departments.data && departments.data.data])

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setDrawerIsOpen(open)
        setState({ ...state, [anchor]: open });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            assoc_type: {
                name: "-",
                value: 0,
                id: null
            },
        },
    });

    const list = (anchor) => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        >
            <div
                className={
                    `flex flex-row ${minCaratteri ? 'mt-4' : 'my-4'} items-center bg-[#4a4137] gap-2 p-2 rounded-full border border-gray-300 mx-2 px-12 cursor-pointer relative`
                }
            >
                <img
                    onClick={() => navigate("/")}
                    alt={"img"}
                    src={lente}
                    className={"object-contain h-6 absolute cursor-pointer left-4"}
                />
                <TextField
                    id="search-input-mobile"
                    sx={{ input: { color: "white" } }}
                    name={"search"}
                    value={search}
                    placeholder={"Cerca..."}
                    onChange={(e) => setSearch(e.target.value)}
                    variant="standard"
                    InputProps={{
                        disableUnderline: true,
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (search.length > 3) {
                                localStorage.setItem('search', JSON.stringify(search));
                                setMinCaratteri(false)
                                toggleDrawer("left", false)
                                if (location.pathname === '/search') {
                                    navigate(0)
                                }
                                navigate('/search')
                            } else {
                                setMinCaratteri(true)
                            }

                        }
                    }}
                />
            </div>
            {
                minCaratteri && <div className="text-center text-[#ff0000] mb-4">
                    Inserire minimo 4 caratteri
                </div>
            }
            {/* <div
                className={
                    "flex flex-row items-center bg-[#4a4137] gap-2 p-2 justify-center rounded-full border border-gray-300 mx-2 px-6 cursor-pointer hover:opacity-50"
                }
            >
                <img alt={"img"} src={vicinoAme} className={"object-contain h-8"} />
                <div
                    onClick={() => { navigate('/vicino-a-me') }}
                    className={"text-lg text-white pl-4"}
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    Vicino a me
                </div>
            </div> */}
            <div
                className={
                    "flex flex-row items-center bg-[#4a4137] my-4 gap-2 p-2 justify-center rounded-full border border-gray-300 mx-2 px-6 cursor-pointer hover:opacity-50"
                }
            >
                <img alt={"img"} src={areaRiservata} className={"object-contain h-6"} />
                <div
                    className={"text-lg text-white pl-4"}
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                    onClick={(event) => { count.profile ? setAnchorEl3(event.currentTarget) : navigate('/login') }}
                >
                    {count.profile ? (count.profile.name + " " + count.profile.surname) : "Area riservata"}
                </div>
                {count.profile ?
                    <Menu
                        MenuListProps={{ disablePadding: true }}
                        anchorEl={anchorEl3}
                        open={open3}
                        onClose={() => setAnchorEl3(null)}>
                        <MenuItem
                            onMouseEnter={(e) => {
                                e.target.style.color = "#ffffff"
                                e.target.style.backgroundColor = "#df5b23"
                            }
                            }
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#ffffff"
                                e.target.style.color = "#495054"
                            }
                            }
                            onClick={() => {
                                navigate('/user')
                                setAnchorEl3(null);
                            }}>
                            Dati Personali
                        </MenuItem>
                        {
                            count && count.profile && count.profile['user_partner'] === '1' && count.profile['user_partner_state'] === 'accepted' &&
                            <MenuItem
                                onMouseEnter={(e) => {
                                    e.target.style.color = "#ffffff"
                                    e.target.style.backgroundColor = "#df5b23"
                                }
                                }
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = "#ffffff"
                                    e.target.style.color = "#495054"
                                }
                                }
                                onClick={() => {
                                    navigate('/carta')
                                    setAnchorEl3(null);
                                }}>
                                Tessera associativa
                            </MenuItem>
                        }

                        <MenuItem
                            onMouseEnter={(e) => {
                                e.target.style.color = "#ffffff"
                                e.target.style.backgroundColor = "#df5b23"
                            }
                            }
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#ffffff"
                                e.target.style.color = "#495054"
                            }
                            }
                            onClick={() => {
                                navigate('/ricerche-salvate')
                                setAnchorEl3(null);
                            }}>
                            Le mie ricerche salvate
                        </MenuItem>
                        <MenuItem
                            onMouseEnter={(e) => {
                                e.target.style.color = "#ffffff"
                                e.target.style.backgroundColor = "#df5b23"
                            }
                            }
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#ffffff"
                                e.target.style.color = "#495054"
                            }
                            }
                            onClick={() => {
                                navigate("/iscrizioni-trek");
                                setAnchorEl3(null);
                            }}>
                            Le mie iscrizioni ai trek
                        </MenuItem>
                        <MenuItem
                            onMouseEnter={(e) => {
                                e.target.style.color = "#ffffff"
                                e.target.style.backgroundColor = "#df5b23"
                            }
                            }
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#ffffff"
                                e.target.style.color = "#495054"
                            }
                            }
                            onClick={() => {
                                navigate("/effettuati");
                                setAnchorEl3(null);
                            }}>
                            Trek effettuati
                        </MenuItem>
                        <MenuItem
                            onMouseEnter={(e) => {
                                e.target.style.color = "#ffffff"
                                e.target.style.backgroundColor = "#df5b23"
                            }
                            }
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#ffffff"
                                e.target.style.color = "#495054"
                            }
                            }
                            onClick={() => {
                                navigate("/iscrizioni-corsi-eventi");
                                setAnchorEl3(null);
                            }}>
                            Iscrizioni ad eventi/corsi
                        </MenuItem>
                        {
                            env.borsellinoFunction && count && count.profile && count.profile['user_partner'] === '1' && count.profile['user_partner_state'] === 'accepted' &&
                            <MenuItem
                                onMouseEnter={(e) => {
                                    e.target.style.color = "#ffffff"
                                    e.target.style.backgroundColor = "#df5b23"
                                }
                                }
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = "#ffffff"
                                    e.target.style.color = "#495054"
                                }
                                }
                                onClick={() => {
                                    navigate("/borsellino-elettronico");
                                    setAnchorEl3(null);
                                }}>
                                Borsellino elettronico
                            </MenuItem>
                        }

                        <MenuItem
                            onMouseEnter={(e) => {
                                e.target.style.color = "#ffffff"
                                e.target.style.backgroundColor = "#df5b23"
                            }
                            }
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#ffffff"
                                e.target.style.color = "#495054"
                            }
                            }
                            onClick={() => {
                                navigate("/privacy");
                                setAnchorEl3(null);
                            }}>
                            Privacy
                        </MenuItem>
                        <MenuItem
                            onMouseEnter={(e) => {
                                e.target.style.color = "#ffffff"
                                e.target.style.backgroundColor = "#df5b23"
                            }
                            }
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#ffffff"
                                e.target.style.color = "#495054"
                            }
                            }
                            onClick={() => {
                                dispatch.count.setToken(null);
                                dispatch.count.setProfile(null);
                                navigate("/login");
                                setAnchorEl3(null);
                            }}>
                            Esci
                        </MenuItem>
                    </Menu>
                    : ""
                }
            </div>
            <List>
                <ListItemButton>
                    <div className={"flex flex-row items-center w-full justify-between"}>
                        <div
                            role="button"
                            onClick={() => setShowAss((val) => !val)}
                            className={"text-md text-amber-900 font-bold"}
                        >
                            ASSOCIAZIONE
                        </div>
                        {!showAss ? (
                            <KeyboardArrowDownIcon
                                onClick={() => setShowAss((val) => !val)}
                                className={"text-amber-900"}
                            />
                        ) : (
                            <KeyboardArrowUpIcon
                                onClick={() => setShowAss((val) => !val)}
                                className={"text-amber-900"}
                            />
                        )}
                    </div>
                </ListItemButton>
                {showAss && (
                    <>
                        <ListItemButton onClick={() => navigate("/chi-siamo")}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                Chi siamo
                            </div>
                        </ListItemButton>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton onClick={() => navigate("/valori")}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                I nostri valori
                            </div>
                        </ListItemButton>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton>
                            <div className={"ml-2 flex flex-row items-center w-full justify-between"}>
                                <div
                                    onClick={() => setShowProgetti((val) => !val)}
                                    className={"text-md text-amber-900 font-bold"}
                                >
                                    Progetti
                                </div>
                                {!showProgetti ? (
                                    <KeyboardArrowDownIcon
                                        onClick={() => setShowProgetti((val) => !val)}
                                        className={"text-amber-900"}
                                    />
                                ) : (
                                    <KeyboardArrowUpIcon
                                        onClick={() => setShowProgetti((val) => !val)}
                                        className={"text-amber-900"}
                                    />
                                )}
                            </div>
                        </ListItemButton>
                        {
                            showProgetti && (
                                <>
                                    <ListItemButton onClick={() => { navigate("/area-giovani"); }}>
                                        <div className={"ml-4 text-md text-amber-900 font-bold"}>
                                            Area Giovani
                                        </div>
                                    </ListItemButton>
                                    <Divider sx={{ marginLeft: 3 }} />
                                    <ListItemButton onClick={() => navigate("/iniziative-per-ambiente")}>
                                        <div className={"ml-4 text-md text-amber-900 font-bold"}>
                                            Iniziative per l'ambiente
                                        </div>
                                    </ListItemButton>
                                    {/* <Divider sx={{ marginLeft: 3 }} />
                                    <ListItemButton onClick={() => navigate("/Accoglienza")}>
                                        <div className={"ml-4 text-md text-amber-900 font-bold"}>
                                            Accoglienza
                                        </div>
                                    </ListItemButton> */}
                                    <Divider sx={{ marginLeft: 3 }} />
                                    <ListItemButton onClick={() => navigate("/trek-solidali")}>
                                        <div className={"ml-4 text-md text-amber-900 font-bold"}>
                                            Trek inclusivi e solidali
                                        </div>
                                    </ListItemButton>
                                    <Divider sx={{ marginLeft: 3 }} />
                                    <ListItemButton onClick={() => navigate("/formazione")}>
                                        <div className={"ml-4 text-md text-amber-900 font-bold"}>
                                            Formazione
                                        </div>
                                    </ListItemButton>
                                </>
                            )
                        }
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton onClick={() => navigate("/i-nostri-volontari")}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                I nostri volontari
                            </div>
                        </ListItemButton>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton onClick={() => navigate("/organismi")}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                Organismi
                            </div>
                        </ListItemButton>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton onClick={() => navigate("/statuto-e-regolamenti")}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                Statuto
                            </div>
                        </ListItemButton>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton onClick={() => navigate("/bilancio-sociale")}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                Bilancio sociale
                            </div>
                        </ListItemButton>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton onClick={() => navigate("/bilanci-annuali")}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                Bilanci
                            </div>
                        </ListItemButton>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton onClick={() => navigate("/faq")}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                Faq
                            </div>
                        </ListItemButton>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton onClick={() => navigate("/coperture-assicurative")}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                Assicurazioni
                            </div>
                        </ListItemButton>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton onClick={() => navigate("/contatti")}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                Contatti
                            </div>
                        </ListItemButton>
                        {createPagesArray(listPages && listPages.data && listPages.data.data && listPages.data.data[1] ? listPages.data.data[1].associazione : []).map((page, index) => {
                            return <>
                                <ListItemButton key={index}>
                                    <div className={"ml-2 flex flex-row items-center w-full justify-between"}>
                                        <div
                                            onClick={() => { page.pages.length > 0 ? setShowNazionaliAssoc((val) => !val) : navigate("/sezioni/Nazionale" + page.to) }}
                                            className={"text-md text-amber-900 font-bold"}
                                        >
                                            {page.name}
                                        </div>
                                        {
                                            page.pages.length > 0 ?
                                                (!showNazionaliAssoc || !showNazionaliAssoc[index] || !showNazionaliAssoc[index].active ? (
                                                    <KeyboardArrowDownIcon
                                                        onClick={() => setShowNazionaliAssoc((prevState) => {
                                                            let newValue = prevState;
                                                            return newValue.map((anchor) => {
                                                                if (anchor.name === newValue[index].name) {
                                                                    return {
                                                                        name: anchor.name,
                                                                        active: true
                                                                    }
                                                                }
                                                                return {
                                                                    name: anchor.name,
                                                                    active: false
                                                                };
                                                            })
                                                        })}
                                                        className={"text-amber-900"}
                                                    />
                                                ) : (
                                                    <KeyboardArrowUpIcon
                                                        onClick={() => setShowNazionaliAssoc((prevState) => {
                                                            let newValue = prevState;
                                                            return newValue.map((anchor) => {
                                                                if (anchor.name === newValue[index].name) {
                                                                    return {
                                                                        name: anchor.name,
                                                                        active: false
                                                                    }
                                                                }
                                                                return {
                                                                    name: anchor.name,
                                                                    active: false
                                                                }
                                                            })
                                                        })}
                                                        className={"text-amber-900"}
                                                    />
                                                ))
                                                : ''

                                        }
                                    </div>
                                </ListItemButton>
                                {

                                    showNazionaliAssoc && showNazionaliAssoc[index] && showNazionaliAssoc[index].active ?
                                        <>
                                            <ListItemButton key={index + `${index}`}>
                                                <div className={"ml-4 flex flex-row items-center w-full justify-between"}>
                                                    <div
                                                        onClick={() => { navigate("/sezioni/Nazionale" + page.to) }}
                                                        className={"text-md text-amber-900 font-bold"}
                                                    >
                                                        {page.name}
                                                    </div>
                                                </div>
                                            </ListItemButton>
                                            {page.pages.map((pagef, index) => {
                                                return <ListItemButton key={index + `${index}`}>
                                                    <div className={"ml-4 flex flex-row items-center w-full justify-between"}>
                                                        <div
                                                            onClick={() => {
                                                                navigate(
                                                                    "/sezioni/Nazionale/" +
                                                                    page.name +
                                                                    pagef.to
                                                                )
                                                            }}
                                                            className={"text-md text-amber-900 font-bold"}
                                                        >
                                                            {pagef.name}
                                                        </div>
                                                    </div>
                                                </ListItemButton>
                                            })}
                                        </>
                                        : ''
                                }
                            </>
                        })}
                    </>
                )}
                {!showTrek && <Divider />}
                <ListItemButton>
                    <div className={"flex flex-row items-center w-full justify-between"}>
                        <div
                            role="button"
                            onClick={() => setShowTrek((val) => !val)}
                            className={"text-md text-amber-900 font-bold"}
                        >
                            I NOSTRI TREK
                        </div>
                        {!showTrek ? (
                            <KeyboardArrowDownIcon
                                onClick={() => setShowTrek((val) => !val)}
                                className={"text-amber-900"}
                            />
                        ) : (
                            <KeyboardArrowUpIcon
                                onClick={() => setShowTrek((val) => !val)}
                                className={"text-amber-900"}
                            />
                        )}
                    </div>
                </ListItemButton>
                {showTrek && (
                    <>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton onClick={() => { navigate('/trek-italia') }}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                In Italia
                            </div>
                        </ListItemButton>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton onClick={() => { navigate('/trek-estero') }}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                Estero
                            </div>
                        </ListItemButton>
                        <ListItemButton onClick={() => { navigate('/come-iscriversi-ai-trek') }}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                Come iscriversi ai nostri trek
                            </div>
                        </ListItemButton>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton>
                            <div className={"ml-2 flex flex-row items-center w-full justify-between"}>
                                <div
                                    onClick={() => setShowClassificazione((val) => !val)}
                                    className={"text-md text-amber-900 font-bold"}
                                >
                                    Classificazione
                                </div>
                                {!showClassificazione ? (
                                    <KeyboardArrowDownIcon
                                        onClick={() => setShowClassificazione((val) => !val)}
                                        className={"text-amber-900"}
                                    />
                                ) : (
                                    <KeyboardArrowUpIcon
                                        onClick={() => setShowClassificazione((val) => !val)}
                                        className={"text-amber-900"}
                                    />
                                )}
                            </div>
                        </ListItemButton>
                        {
                            showClassificazione && (
                                <>
                                    <ListItemButton onClick={() => { navigate("/valutazione-trek"); }}>
                                        <div className={"ml-4 text-md text-amber-900 font-bold"}>
                                            A OGNUNO IL SUO TREK
                                        </div>
                                    </ListItemButton>
                                    <Divider sx={{ marginLeft: 3 }} />
                                    <ListItemButton onClick={() => navigate("/tipologia-trek")}>
                                        <div className={"ml-4 text-md text-amber-900 font-bold"}>
                                            TIPOLOGIA DEI TREK
                                        </div>
                                    </ListItemButton>
                                </>
                            )
                        }
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton>
                            <div className={"ml-2 flex flex-row items-center w-full justify-between"}>
                                <div
                                    onClick={() => setShowPreparati((val) => !val)}
                                    className={"text-md text-amber-900 font-bold"}
                                >
                                    Preparati al tuo trek
                                </div>
                                {!showPreparati ? (
                                    <KeyboardArrowDownIcon
                                        onClick={() => setShowPreparati((val) => !val)}
                                        className={"text-amber-900"}
                                    />
                                ) : (
                                    <KeyboardArrowUpIcon
                                        onClick={() => setShowPreparati((val) => !val)}
                                        className={"text-amber-900"}
                                    />
                                )}
                            </div>
                        </ListItemButton>
                        {
                            showPreparati && (
                                <>
                                    <ListItemButton onClick={() => { navigate("/equipaggiamento"); }}>
                                        <div className={"ml-4 text-md text-amber-900 font-bold"}>
                                            Equipaggiamento
                                        </div>
                                    </ListItemButton>
                                    <Divider sx={{ marginLeft: 3 }} />
                                    <ListItemButton onClick={() => navigate("/abbigliamento")}>
                                        <div className={"ml-4 text-md text-amber-900 font-bold"}>
                                            Abbigliamento
                                        </div>
                                    </ListItemButton>
                                    <Divider sx={{ marginLeft: 3 }} />
                                    <ListItemButton onClick={() => navigate("/allenamento")}>
                                        <div className={"ml-4 text-md text-amber-900 font-bold"}>
                                            Allenamento
                                        </div>
                                    </ListItemButton>
                                    <Divider sx={{ marginLeft: 3 }} />
                                    <ListItemButton onClick={() => navigate("/alimentazione")}>
                                        <div className={"ml-4 text-md text-amber-900 font-bold"}>
                                            L'alimentazione nell'escursionismo
                                        </div>
                                    </ListItemButton>
                                </>
                            )
                        }
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton onClick={() => {
                            localStorage.setItem('accompagnatoreSez', 0)
                            localStorage.setItem('accompagnatorePage', 1)
                            navigate("/accompagnatori")
                        }}>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                Accompagnatori
                            </div>
                        </ListItemButton>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                Dicono di noi
                            </div>
                        </ListItemButton>
                        <Divider sx={{ marginLeft: 3 }} />
                        <ListItemButton>
                            <div className={"ml-2 text-md text-amber-900 font-bold"}>
                                Immagini dai trek
                            </div>
                        </ListItemButton>
                        {createPagesArray(listPages && listPages.data && listPages.data.data && listPages.data.data[1] ? listPages.data.data[1].trek : []).map((page, index) => {
                            return <>
                                <ListItemButton key={index}>
                                    <div className={"ml-2 flex flex-row items-center w-full justify-between"}>
                                        <div
                                            onClick={() => { page.pages.length > 0 ? setShowNazionaliTrek((val) => !val) : navigate("/sezioni/Nazionale" + page.to) }}
                                            className={"text-md text-amber-900 font-bold"}
                                        >
                                            {page.name}
                                        </div>
                                        {
                                            page.pages.length > 0 ?
                                                (!showNazionaliTrek || !showNazionaliTrek[index] || !showNazionaliTrek[index].active ? (
                                                    <KeyboardArrowDownIcon
                                                        onClick={() => setShowNazionaliTrek((prevState) => {
                                                            let newValue = prevState;
                                                            return newValue.map((anchor) => {
                                                                if (anchor.name === newValue[index].name) {
                                                                    return {
                                                                        name: anchor.name,
                                                                        active: true
                                                                    }
                                                                }
                                                                return {
                                                                    name: anchor.name,
                                                                    active: false
                                                                };
                                                            })
                                                        })}
                                                        className={"text-amber-900"}
                                                    />
                                                ) : (
                                                    <KeyboardArrowUpIcon
                                                        onClick={() => setShowNazionaliTrek((prevState) => {
                                                            let newValue = prevState;
                                                            return newValue.map((anchor) => {
                                                                if (anchor.name === newValue[index].name) {
                                                                    return {
                                                                        name: anchor.name,
                                                                        active: false
                                                                    }
                                                                }
                                                                return {
                                                                    name: anchor.name,
                                                                    active: false
                                                                }
                                                            })
                                                        })}
                                                        className={"text-amber-900"}
                                                    />
                                                ))
                                                : ''

                                        }
                                    </div>
                                </ListItemButton>
                                {

                                    showNazionaliTrek && showNazionaliTrek[index] && showNazionaliTrek[index].active ?
                                        <>
                                            <ListItemButton key={index + `${index}`}>
                                                <div className={"ml-4 flex flex-row items-center w-full justify-between"}>
                                                    <div
                                                        onClick={() => { navigate("/sezioni/Nazionale" + page.to) }}
                                                        className={"text-md text-amber-900 font-bold"}
                                                    >
                                                        {page.name}
                                                    </div>
                                                </div>
                                            </ListItemButton>
                                            {page.pages.map((pagef, index) => {
                                                return <ListItemButton key={index + `${index}`}>
                                                    <div className={"ml-4 flex flex-row items-center w-full justify-between"}>
                                                        <div
                                                            onClick={() => {
                                                                navigate(
                                                                    "/sezioni/Nazionale/" +
                                                                    page.name +
                                                                    pagef.to
                                                                )
                                                            }}
                                                            className={"text-md text-amber-900 font-bold"}
                                                        >
                                                            {pagef.name}
                                                        </div>
                                                    </div>
                                                </ListItemButton>
                                            })}
                                        </>
                                        : ''
                                }
                            </>
                        })}
                    </>
                )}
                {!showTrek && <Divider />}
                <ListItemButton>
                    <div className={"flex flex-row items-center w-full justify-between"}>
                        <div
                            role="button"
                            onClick={() => setShowSezioni((val) => !val)}
                            className={"text-md text-amber-900 font-bold"}
                        >
                            SEZIONI
                        </div>
                        {!showSezioni ? (
                            <KeyboardArrowDownIcon
                                onClick={() => setShowSezioni((val) => !val)}
                                className={"text-amber-900"}
                            />
                        ) : (
                            <KeyboardArrowUpIcon
                                onClick={() => setShowSezioni((val) => !val)}
                                className={"text-amber-900"}
                            />
                        )}
                    </div>
                </ListItemButton>
                {
                    showSezioni && departments && departments.data && departments.data.data ?
                        departments.data.data.map((department, index) => {
                            let indexDep = index - 1
                            if (department.name === 'Nazionale') {
                                return ''
                            }
                            let pages = createPagesArray(listPages && listPages.data && listPages.data.data && listPages.data.data[department.id] ? listPages.data.data[department.id] : [])
                            return (
                                <React.Fragment key={index}>
                                    <ListItemButton>
                                        <div className={"flex flex-row items-center w-full justify-between"}>
                                            <div
                                                onClick={() => pages.length === 0 ? navigate("/sezioni/" + department.name)
                                                    : changedShowPages(department.name.includes("Sezione")
                                                        ? department.name.substring(8)
                                                        : department.name)}
                                                className={"ml-2 text-md text-amber-900 font-bold"}
                                            >
                                                {department.name.includes("Sezione")
                                                    ? department.name.substring(8)
                                                    : department.name}
                                            </div>
                                            {
                                                pages.length > 0 ?
                                                    (
                                                        showPages && showPages[index] && !showPages[index].active ?
                                                            (
                                                                <KeyboardArrowDownIcon
                                                                    onClick={() => changedShowPages(department.name.includes("Sezione")
                                                                        ? department.name.substring(8)
                                                                        : department.name)}
                                                                    className={"text-amber-900"}
                                                                />
                                                            )
                                                            : (
                                                                <KeyboardArrowUpIcon
                                                                    onClick={() => changedShowPages(department.name.includes("Sezione")
                                                                        ? department.name.substring(8)
                                                                        : department.name)}
                                                                    className={"text-amber-900"}
                                                                />
                                                            )
                                                    )
                                                    : ''}
                                        </div>
                                    </ListItemButton>
                                    {
                                        showPages[index].active && pages.length > 0 ?
                                            <React.Fragment key={0}>
                                                <ListItemButton onClick={() => navigate("/sezioni/" + department.name)}>
                                                    <div className={"ml-4 text-md text-amber-900 font-bold"}>
                                                        Dettagli della sezione
                                                    </div>
                                                </ListItemButton>
                                                {
                                                    pages.map((page, index) => {
                                                        return (
                                                            <React.Fragment key={index + 1}>
                                                                <ListItemButton >
                                                                    <div className={"flex flex-row items-center w-full justify-between"}>
                                                                        <div
                                                                            onClick={() => {
                                                                                if (page.pages.length === 0) {
                                                                                    navigate('/sezioni/' + department.name + page.to)
                                                                                }
                                                                            }}
                                                                            className={" ml-4 text-md text-amber-900 font-bold"}
                                                                        >
                                                                            {page.name}
                                                                        </div>
                                                                        {
                                                                            page.pages.length > 0 ?
                                                                                (
                                                                                    !showPagesPages[indexDep][index].active ?
                                                                                        (
                                                                                            <KeyboardArrowDownIcon
                                                                                                onClick={() => { changedShowPagesPages(page.name, indexDep) }}
                                                                                                className={"text-amber-900"}
                                                                                            />
                                                                                        )
                                                                                        : (
                                                                                            <KeyboardArrowUpIcon
                                                                                                onClick={() => { changedShowPagesPages(page.name, indexDep) }}
                                                                                                className={"text-amber-900"}
                                                                                            />
                                                                                        )
                                                                                )
                                                                                : ''
                                                                        }
                                                                    </div>
                                                                </ListItemButton>
                                                                {
                                                                    page.pages.length > 0 && showPagesPages[indexDep][index].active ?
                                                                        <React.Fragment >
                                                                            <ListItemButton onClick={() => navigate('/sezioni/' + department.name + page.to)}>
                                                                                <div className={"ml-6 text-md text-amber-900 font-bold"}>
                                                                                    {page.name}
                                                                                </div>
                                                                            </ListItemButton>
                                                                            {
                                                                                page.pages.map((pagef, index) => {
                                                                                    return (
                                                                                        <React.Fragment key={index}>
                                                                                            <Divider sx={{ marginLeft: 3 }} />
                                                                                            <ListItemButton onClick={() => navigate('/sezioni/' + department.name + page.to + pagef.to)}>
                                                                                                <div className={"ml-6 text-md text-amber-900 font-bold"}>
                                                                                                    {pagef.name}
                                                                                                </div>
                                                                                            </ListItemButton>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </React.Fragment>
                                                                        : ''
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </React.Fragment>
                                            : ''
                                    }
                                    {index === (departments.data.data.length - 1) ? true : !showPages[index].active && <Divider key={index} />}
                                </React.Fragment>
                            )
                        })
                        : ''
                }
                {!showSezioni && <Divider />}
                <ListItemButton onClick={() => navigate("/trek-magazine")}>
                    <div className={"text-md text-amber-900 font-bold"}>
                        TREK MAGAZINE
                    </div>
                </ListItemButton>
                <Divider />
                <ListItemButton onClick={() => navigate("/cataloghi")}>
                    <div className={"text-md text-amber-900 font-bold"}>CATALOGHI</div>
                </ListItemButton>
                <Divider />
                <ListItemButton onClick={() => navigate("/news")}>
                    <div className={"text-md text-amber-900 font-bold"}>
                        NEWS
                    </div>
                </ListItemButton>
                <Divider />
                <ListItemButton onClick={() => navigate("/eventi")}>
                    <div className={"text-md text-amber-900 font-bold"}>
                        EVENTI
                    </div>
                </ListItemButton>
                <ListItemButton onClick={() => navigate("/corsi")}>
                    <div className={"text-md text-amber-900 font-bold"}>
                        CORSI
                    </div>
                </ListItemButton>
            </List>
            {
                count && count.profile && count.profile.roles && count.profile.roles.length > 0 &&
                <div
                    onClick={() => {
                        // window.location.replace(`${env.url_panel}login?u=${count.profile.username}&p=${count.password}`)
                        // const axiosConfig = {
                        //     headers: {
                        //         Accept: "application/json",
                        //         "Content-Type": "application/json",
                        //     },
                        // };

                        // const decryptedBytes = CryptoJS.AES.decrypt(count.password, env.secret_key);
                        // const decryptedPassword = decryptedBytes.toString(CryptoJS.enc.Utf8);

                        api.get('users/login-panel').then(res => {

                            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

                            // Controllo per Iphone
                            if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
                                setIsIphone(true)
                                setLinkForIphone(`${env.url_panel}login?u=${count.profile.username}&p=${count.password}&t=${res.data.data}`)
                            } else {
                                let a = document.createElement("a");
                                document.body.appendChild(a);
                                a.style = "display: none";
                                a.href = `${env.url_panel}login?u=${count.profile.username}&p=${count.password}&t=${res.data.data}`;
                                a.target = '_blank'
                                a.click();
                                document.body.removeChild(a);
                            }
                        })
                    }}
                    className={
                        "uppercase bg-[#c44116] text-[#ffffff] mx-4 p-4 text-md cursor-pointer hover:opacity-50"
                    }
                    style={{
                        fontFamily: "bilo",
                        fontWeight: 500,
                        fontStyle: "normal",
                    }}>
                    GESTIONALE
                </div>
            }
        </Box>
    );

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar className={"bg-[#f0e9e3] w-full !grid grid-cols-3 !px-2 "}>
                        <a href={`${window.location.href.replaceAll('#main', "").replaceAll('#filter', "")}#main`} className="-indent-[9999px] active:indent-0 focus:indent-0 absolute bg-white text-black">Vai all'area principale</a>
                        <IconButton
                            className="justify-self-start"
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={toggleDrawer("left", true)}
                        >
                            <MenuIcon sx={{ color: "black" }} />
                        </IconButton>
                        {/*<div className={"flex flex-row items-center justify-between gap-2"}>*/}
                        <img
                            onClick={() => { navigate('/') }}
                            alt={"img"}
                            src={logo}
                            className={"object-contain w-1/2 min-w-[150px] h-20 justify-self-center hover:cursor-pointer"}
                        />
                        {
                            count && count.profile && (count.profile['user_partner'] === '1' || count.profile['user_partner_state'] === 'pending') ?
                                ''
                                : count.profile ?
                                    <Link to={"/register/socio"}
                                        className={
                                            "uppercase bg-[#c44116] p-4 text-md cursor-pointer justify-self-end w-fit hover:opacity-50"
                                        }
                                        style={{
                                            fontFamily: "bilo",
                                            fontWeight: 500,
                                            fontStyle: "normal",
                                        }}>
                                        ASSOCIATI!
                                    </Link>

                                    : <Link to={'/login'}
                                        onClick={() => {
                                            localStorage.setItem('from_associa', true)
                                        }}
                                        className={
                                            "uppercase bg-[#c44116] p-4 text-md cursor-pointer justify-self-end w-fit hover:opacity-50"
                                        }
                                        style={{
                                            fontFamily: "bilo",
                                            fontWeight: 500,
                                            fontStyle: "normal",
                                        }}>
                                        ASSOCIATI!
                                    </Link >
                        }
                        {
                            count && count.profile &&
                            (count.profile['user_partner'] === '1' &&
                                count.profile['user_partner_state'] === 'accepted') &&
                            ((today >= rinnovaDate && carta && carta.data && carta.data.data && carta.data.data.year_membership <= today.getFullYear()) ||
                                (carta && carta.data && carta.data.data && carta.data.data.year_membership < today.getFullYear())) &&

                            <div
                                onClick={() => {
                                    api.get("/users/get-association-types").then((res) => {
                                        setAssocType(res.data.data);
                                        return res;
                                    });
                                    setOpenRinnova(true);
                                }}
                                id="button_rinnova_tessera"
                                className={
                                    " uppercase bg-[#c44116] text-md text-center cursor-pointer justify-self-end w-fit max-w-[100px] hover:opacity-50"
                                }
                                style={{
                                    fontFamily: "bilo",
                                    fontWeight: 500,
                                    fontStyle: "normal",
                                }}>
                                RINNOVA ASSOCIAZIONE
                            </div>
                        }
                    </Toolbar>
                </AppBar>
            </Box>
            <Drawer
                PaperProps={{
                    sx: {
                        backgroundColor: "#f0e9e3",
                    },
                }}
                anchor={"left"}
                open={state["left"]}
                onClose={toggleDrawer("left", false)}
            >
                {list("left")}
            </Drawer>
            {/* RINNOVA TESSERA */}
            <DialogCustom
                title={"Rinnova tessera"}
                fullScreen={false}
                open={openRinnova}
                handleClose={() => {
                    if (
                        formik.values.assoc_type.name === "Sostenitore" &&
                        formik.values.assoc_type.value < 40
                    ) {
                    } else {
                        setOpenRinnova(false);
                        setOpenPaymentMethod(true);
                    }
                }}
                closeModal={() => setOpenRinnova(false)}
                content={
                    <form className="flex items-center justify-center gap-2">
                        <fieldset>
                            <legend></legend>
                            <InputCustom
                                select
                                fullWidth
                                title={"Tipo di associazione*"}
                                name={"assoc_type"}
                                value={formik.values.assoc_type.name}
                                onChange={(e) => {
                                    let value = assocType && assocType.filter(assoc => assoc.name === e.target.value) && assocType.filter(assoc => assoc.name === e.target.value)[0] && assocType.filter(assoc => assoc.name === e.target.value)[0].value
                                    let id = assocType && assocType.filter(assoc => assoc.name === e.target.value) && assocType.filter(assoc => assoc.name === e.target.value)[0] && assocType.filter(assoc => assoc.name === e.target.value)[0].id
                                    setErrorAssocValue(false);
                                    formik.setFieldValue("assoc_type", {
                                        name: e.target.value,
                                        value: Number(value),
                                        id: id
                                    });
                                }}>
                                <MenuItem
                                    value={'-'}
                                    key={0}>
                                    -
                                </MenuItem>
                                {assocType.map((assoc, index) => {
                                    return (
                                        <MenuItem value={assoc.name} key={index + 1}>
                                            {assoc.name}
                                        </MenuItem>
                                    );
                                })}
                            </InputCustom>
                            <div>
                                <InputCustom
                                    classcontainer={"w-auto"}
                                    disabled={formik.values.assoc_type.name !== "Sostenitore"}
                                    title={formik.values.assoc_type.name === "Sostenitore" ? "a partire da*" : "Importo richiesto*"}
                                    value={formik.values.assoc_type.value}
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min:
                                                formik.values.assoc_type.name === "Sostenitore"
                                                    ? 40
                                                    : formik.values.assoc_type.value,
                                        },
                                    }}
                                    onChange={(e) => {
                                        if (
                                            formik.values.assoc_type.name === "Sostenitore" &&
                                            e.target.value < 40
                                        ) {
                                            setErrorAssocValue(true);
                                        } else {
                                            setErrorAssocValue(false);
                                        }
                                        formik.setFieldValue("assoc_type", {
                                            ...formik.values.assoc_type,
                                            value: e.target.value,
                                        });
                                    }}
                                />
                                {errorAssocValue && (
                                    <InputBilo
                                        className={"text-[#ff0000]"}
                                        title={
                                            `Il tipo associzione "Sostenitore" prevede una quota minima di 40€`
                                        }
                                    />
                                )}
                            </div>
                        </fieldset>
                    </form>
                }
                button={"PROCEDI"}
                buttonDisabled={(formik.values.assoc_type.name === '-') || (formik.values.assoc_type.name === 'Sostenitore' && formik.values.assoc_type.value < 40)}
            />
            {/*PAGAMENTO RINNOVO*/}
            <DialogCustom
                open={openPaymentMethod}
                title={"Scegli"}
                content={
                    <>
                        <InputBilo
                            className={"text-2xl"}
                            title={`Tipo di associazione: ${formik.values.assoc_type.name}`}
                        />
                        <InputBilo
                            className={"text-2xl"}
                            title={`Importo: €${formik.values.assoc_type.value}`}
                        />
                        <InputBilo
                            className={"text-xl"}
                            title={
                                env.borsellinoFunction ? "Preferisci pagare con il borsellino elettronico o con Carta?" : 'Con cosa vuoi pagare?'
                            }
                        />
                    </>
                }
                handleClose={() => {
                    api
                        .post(
                            `users/${count.profile.id}/borsellino/prepareWalletAssociation`,
                            {
                                user_id: count.profile.id,
                                association_type_id: formik.values.assoc_type.id,
                                price: formik.values.assoc_type.value,
                            }
                        )
                        .then((res) => {
                            localStorage.setItem("lastpage", location.pathname);
                            if (res && res.data && res.data.data) {
                                navigate("/payment-success");
                            } else {
                                setErrorAssoc({
                                    open: true,
                                    error:
                                        res && res.data && res.data.errors
                                            ? res.data.errors[Object.keys(res.data.errors)[0]][0]
                                            : "",
                                });
                            }
                            return res;
                        });
                    setOpenPaymentMethod(false);
                }}
                closeModal={() => {
                    setOpenPaymentMethod(false);
                }}
                handleClose2={() => {
                    api
                        .post(`users/${count.profile.id}/associationWithoutWallet`, {
                            user_id: count.profile.id,
                            association_type_id: formik.values.assoc_type.id,
                            price: formik.values.assoc_type.value,
                        })
                        .then((res) => {
                            localStorage.setItem("lastpage", location.pathname);
                            if (res && res.data && res.data.data) {
                                let a = document.createElement("a");
                                document.body.appendChild(a);
                                a.style = "display: none";
                                a.href = res.data.data;
                                a.target = '_blank'
                                a.click();
                                document.body.removeChild(a);
                                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                                let platform = ''
                                // Controllo per Iphone
                                if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
                                    platform = "iOS";
                                }
                                if (platform === "iOS") {
                                    setIsIphone(true)
                                    setLinkForIphone(res.data.data)
                                }
                            } else {
                                setErrorAssoc({
                                    open: true,
                                    error:
                                        res && res.data && res.data.errors
                                            ? res.data.errors[Object.keys(res.data.errors)[0]][0]
                                            : "",
                                });
                            }
                            return res;
                        });

                    setOpenPaymentMethod(false);
                }}
                button={env.borsellinoFunction ? "Borsellino elettronico" : null}
                button2={"Carta"}
            />
            {/* ERROR PAGAMENTO */}
            <DialogCustom
                open={errorAssoc.open}
                title={"Errore"}
                content={
                    <>
                        <InputBilo className={"text-xl"} title={errorAssoc.error} />
                    </>
                }
                button={"Chiudi"}
                handleClose={() => {
                    setErrorAssoc((prevState) => {
                        return {
                            open: false,
                            error: "",
                        };
                    });
                }}
                closeModal={() => {
                    setErrorAssoc((prevState) => {
                        return {
                            open: false,
                            error: "",
                        };
                    });
                }}
            />
            <DialogCustom
                open={isIphone}
                title={"Apertura di un'altra finestra"}
                content={<h2>Stai per lasciare questa pagina, continuare?</h2>}
                handleClose2={() => {
                    setIsIphone(false)
                }}
                handleClose={() => {
                    setIsIphone(false)
                    window.open(linkForIphone)
                }}
                closeModal={() => { setIsIphone(false) }}
                button={'SI'}
                button2={'NO'}
            />
        </div>
    );
};

const mapState = (state) => ({
    count: state.count,
});

export default connect(mapState)(TopbarMobile);
