import React from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import FooterTop from '../Sezioni/FooterTop'
import FooterDown from '../Sezioni/FooterDown'
import img1 from "../assets/img/accompagnatori.jpg";
import InputGimlet from '../components/InputGimlet'
import InputBilo from '../components/InputBilo'
import { Link } from 'react-router-dom'
import env from '../conf/variables'
import legendDifficulty from "../assets/img/scheda_valutazione.61de7991.jpg";

function Equipaggiamento() {
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Equipaggiamento"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
                <div
                    className={"flex flex-row items-center gap-1"}
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    <div>I nostri trek  {">"}</div>
                    <div>Preparati al tuo trek  {">"}</div>
                    <div className={"font-bold"}> Equipaggiamento</div>
                </div>
                <InputGimlet
                    id='main'
                    title={'equipaggiamento'}
                    fontWeight={500}
                    fontStyle="italic"
                    className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                />
                <div className="mb-5 text-xl">
                    <InputBilo
                        title={'ZAINO'}
                        fontWeight={700}
                        className={'text-[#124761] text-2xl'}
                    />
                    <InputBilo
                        title={'Al momento dell’acquisto bisogna provare lo zaino con un po’ di carico. Il peso complessivo dello zaino non dovrebbe superare il 10% del peso di chi lo porta; va infatti preparato limitandosi all\'essenziale, alle cose strettamente utili al trek! (fino a 20/25 litri per trek giornalieri, fino a 35/40 litri per trek di due o più giorni). '}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'Scegliere lo zaino: '}
                        fontWeight={700}
                    />
                    <ul>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'più alto che largo per agevolare il mantenimento dell’equilibrio anche lungo i percorsi più impervi e per evitare che urti o si incastri in passaggi con vegetazione, sassi, strettoie; '}
                                fontWeight={300}
                            />
                        </li>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'con tasche laterali in cui conservare gli oggetti di uso frequente; '}
                                fontWeight={300}
                            />
                        </li>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'con spallacci larghi e imbottiti;'}
                                fontWeight={300}
                            />
                        </li>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'con schienale ergonomico, in tessuto traspirante per non provocare un’eccessiva sudorazione, rigido per aderire il più possibile alla schiena; '}
                                fontWeight={300}
                            />
                        </li>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'con fascione lombare che scarichi il peso sul bacino;'}
                                fontWeight={300}
                            />
                        </li>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'con copri-zaino impermeabile incorporato o acquistabile a parte. '}
                                fontWeight={300}
                            />
                        </li>
                    </ul>
                    <br />
                    <InputBilo
                        title={'Come va usato: '}
                        fontWeight={700}
                    />
                    <ul>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'va indossato ed equilibrato su entrambe le spalle '}
                                fontWeight={300}
                            />
                        </li>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'occorre regolare gli spallacci, in modo che lo zaino non scenda sui glutei per evitare un effetto leva e gravare sulla zona lombare; '}
                                fontWeight={300}
                            />
                        </li>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'occorre allacciare sempre la cintura ventrale perché "scarica" il peso sul bacino e quindi sulle gambe, alleggerendo il carico della schiena; '}
                                fontWeight={300}
                            />
                        </li>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'occorre riempirlo in modo omogeneo ed equilibrato: uno zaino ben caricato "sta in piedi da solo"; '}
                                fontWeight={300}
                            />
                        </li>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'occorre mettere sul fondo e contro la schiena le cose pesanti e sopra quelle leggere e di uso più frequente.'}
                                fontWeight={300}
                            />
                        </li>
                    </ul>
                    <br />
                    <br />
                    <InputBilo title={'Cosa riporre nello zaino:'} fontWeight={700} />
                    <ul>
                        {[
                            'kit di primo soccorso completo di telo termico, cerotti, bende e medicinali personali',
                            'sali minerali',
                            'fascette di plastica e/o scotch americano (in caso di scollatura della suola degli scarponi)',
                            'lacci di ricambio',
                            'accendino',
                            'coltellino multiuso (attenzione ai viaggi in aereo)',
                            'fischietto',
                            'lampada frontale con pile sostitutive',
                            'crema solare',
                            'repellente per insetti',
                            'un sacchetto per piccoli rifiuti',
                        ].map((item, idx) => (
                            <li key={idx} className={'!list-disc ml-8'}>
                                <InputBilo title={item} fontWeight={300} />
                            </li>
                        ))}
                    </ul>
                    <br />
                    <InputBilo title={'… e se dormiamo in rifugio:'} fontWeight={700} />
                    <br />
                    <ul>
                        {[
                            'sacco lenzuolo (o sacco a pelo)',
                            'kit bagno (spazzolino, dentifricio, sapone, guanto per lavarsi, piccolo asciugamano in microfibra)',
                            'tappi per orecchie',
                            '1 pezzo di sapone per lavare i panni, 2 mollette',
                            '1 cambio per la notte (1 maglietta, 1 paio di leggings)',
                            'ciabatte leggere (nel caso non disponibili in rifugio)',
                        ].map((item, idx) => (
                            <li key={idx} className={'!list-disc ml-8'}>
                                <InputBilo title={item} fontWeight={300} />
                            </li>
                        ))}
                    </ul>
                    <br />
                    <InputBilo
                        title={'GLI SCARPONI DA TREKKING'}
                        fontWeight={700}
                        className={'text-[#124761] text-2xl'}
                    />
                    <InputBilo
                        title={'Sono le attrezzature che garantiscono maggiore sicurezza sui sentieri, protezione delle caviglie, minor affaticamento e cura dei piedi. Meglio calzature alte, con suola in Vibram® e membrana in Gore-Tex®, per impermeabilità e traspirazione.'}
                        fontWeight={300}
                    />
                    <ul>
                        {[
                            'misurare lo scarpone con una calza da trekking;',
                            'usare una pedana inclinata per verificare che in discesa le dita non urtino la punta;',
                            'allacciare bene le stringhe per far sì che piede e scarpa siano un tutt’uno.',
                        ].map((item, idx) => (
                            <li key={idx} className={'!list-disc ml-8'}>
                                <InputBilo title={item} fontWeight={300} />
                            </li>
                        ))}
                    </ul>
                    <br />
                    <InputBilo
                        title={'I BASTONCINI TELESCOPICI'}
                        fontWeight={700}
                        className={'text-[#124761] text-2xl'}
                    />
                    <InputBilo
                        title={'Sono il prolungamento artificiale degli arti superiori. Favoriscono l’equilibrio e il bilanciamento del corpo durante la camminata, aiutando a scaricare fino al 30% del peso, soprattutto in discesa.'}
                        fontWeight={300}
                    />
                    <ul>
                        {[
                            'Usare due bastoncini per una maggiore stabilità;',
                            'Controllare che possano essere appesi allo zaino quando non utilizzati.',
                        ].map((item, idx) => (
                            <li key={idx} className={'!list-disc ml-8'}>
                                <InputBilo title={item} fontWeight={300} />
                            </li>
                        ))}
                    </ul>

                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    )
}

export default Equipaggiamento