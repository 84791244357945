import React from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import FooterTop from '../Sezioni/FooterTop'
import FooterDown from '../Sezioni/FooterDown'
import img1 from "../assets/img/accompagnatori.jpg";
import InputGimlet from '../components/InputGimlet'
import InputBilo from '../components/InputBilo'
import { Link } from 'react-router-dom'
import env from '../conf/variables'
import legendDifficulty from "../assets/img/scheda_valutazione.61de7991.jpg";

function ValutazioneTrek() {
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"A ognuno il suo trek"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
                <div
                    className={"flex flex-row items-center gap-1"}
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    <div>I nostri trek  {">"}</div>
                    <div>Classificazione  {">"}</div>
                    <div className={"font-bold"}> A ognuno il suo trek</div>
                </div>
                <InputGimlet
                    id='main'
                    title={'a ognuno il suo trek'}
                    fontWeight={500}
                    fontStyle="italic"
                    className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                />
                <div className="mb-5 text-xl">
                    <InputBilo
                        title={'I trek proposti hanno diversi gradi di difficoltà: per altitudine, dislivello, tipologia dell’ambiente attraversato, lunghezza dei percorsi.'}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'Prima di iscriverti, leggi accuratamente la scheda del trek: la tipologia del trek (itinerante, itinerante con bagaglio trasportato o residenziale), il grado di difficoltà, il dislivello, le ore di cammino e la lunghezza del percorso, eventuali note sulla difficoltà, e valuta la partecipazione in base alle tue esigenze e alle tue condizioni fisiche e preparazione tecnica al cammino. '}
                        fontWeight={300}
                    />
                    <br />
                    <img src={legendDifficulty} alt="legenda" />
                    <br />
                    <InputBilo
                        title={'Tabella di valutazione per aiutare i soci a meglio comprendere l’itinerario e a valutare la propria preparazione fisica per affrontare il trek.'}
                        fontWeight={300}
                    />
                    
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    )
}

export default ValutazioneTrek