import React from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import FooterTop from '../Sezioni/FooterTop'
import FooterDown from '../Sezioni/FooterDown'
import img1 from "../assets/img/accompagnatori.jpg";
import InputGimlet from '../components/InputGimlet'
import InputBilo from '../components/InputBilo'
import env from '../conf/variables'
import { Link } from 'react-router-dom'

function ComeIscriversiAiTrek() {
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Come iscriversi ai trek"}
                img={img1}
                position={"center"}
            />
            <div className="bg-white relative z-40 p-8 pt-20 flex flex-col">
                <div
                    className="flex flex-row items-center gap-1"
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    <div>I nostri trek {">"}</div>
                    <div>Preparati al tuo trek {">"}</div>
                    <div className="font-bold">Come iscriversi</div>
                </div>

                <InputGimlet
                    id='main'
                    title={'come iscriversi ai trek'}
                    fontWeight={500}
                    fontStyle="italic"
                    className="my-6 uppercase text-4xl text-[#DF5B23]"
                />

                <div className="mb-5 text-xl">
                    <InputBilo
                        title={'Per partecipare a un trek è necessario essere soci Trekking Italia in regola con la quota associativa annuale.'}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'Non hai ancora la tessera di Trekking Italia? Per prima cosa, registrati al nostro sito. Dopo la prima registrazione, riceverai una mail di conferma e, entrando con le tue credenziali, dall\'area riservata potrai pagare la tessera online con carta di credito cliccando su Tessera associativa. Se hai dubbi sul primo accesso, guarda questo '}
                        fontWeight={300}
                        className={'inline'}
                    />
                    <Link target='_blank' className={'inline text-[#124761] underline text-lg'} to={'https://www.canva.com/design/DAF2fO-z1Gw/0B52Lg885YE6mnK7LLvtNw/view?utm_content=DAF2fO-z1Gw&utm_campaign=designshare&utm_medium=link&utm_source=editor#1'}>
                        tutorial
                    </Link>
                    <InputBilo
                        title={'.'}
                        fontWeight={300}
                        className={'inline'}
                    />
                    <br />
                    <br />
                    <InputBilo
                        title={'Devi solo effettuare il rinnovo perché la tessera è scaduta? Assicurati di aver effettuato il login al nostro sito. Entrando con le tue credenziali, dall\'area riservata potrai pagare la tessera online con carta di credito cliccando su Tessera associativa.'}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'In entrambi i casi potrai effettuare il tesseramento anche recandoti presso la sede della tua regione, oppure tramite bonifico.'}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'È possibile iscriversi ai trek in programma con modalità differenti, in base all’organizzazione della Sezione organizzatrice del trek e alla tipologia del trek:'}
                        fontWeight={300}
                    />
                    <ul>
                        {[
                            'iscrivendosi online;',
                            'contattando la Segreteria della Sezione regionale;',
                            'pagando direttamente on line con carte bancarie o con l\'utilizzo del borsellino elettronico, tramite bonifico inviando copia della ricevuta alla Segreteria della sezione organizzatrice del trek.',
                        ].map((item, idx) => (
                            <li key={idx} className="!list-disc ml-8">
                                <InputBilo title={item} fontWeight={300} />
                            </li>
                        ))}
                        <li className="!list-disc ml-8">
                            <InputBilo title={'pagando direttamente on line con carte bancarie '} fontWeight={300} className={'inline'} />
                            {
                                env.borsellinoFunction &&
                                <>
                                    <InputBilo title={'o con l\'utilizzo del '} fontWeight={300} className={'inline'} />
                                    <Link className={'inline text-[#124761] underline text-lg'} to={'/borsellino-elettronico'}>
                                        borsellino elettronico
                                    </Link>
                                </>
                            }
                        </li>

                    </ul>
                    <br />
                    <InputBilo
                        title={'Nella Scheda trek troverai le informazioni riguardanti il costo di partecipazione, cosa include o non include la quota di partecipazione, i termini di iscrizione, le modalità di recesso e di annullamento.'}
                        fontWeight={300}
                    />
                </div>
            </div>

            <FooterTop />
            <FooterDown />
        </>
    )
}

export default ComeIscriversiAiTrek
