import React from 'react'
import TopBarIndex from '../components/TopBarIndex'
import BackgroundTitle from '../components/BackgroundTitle'
import FooterTop from '../Sezioni/FooterTop'
import FooterDown from '../Sezioni/FooterDown'
import img1 from "../assets/img/accompagnatori.jpg";
import InputGimlet from '../components/InputGimlet'
import InputBilo from '../components/InputBilo'
import { Link } from 'react-router-dom'
import env from '../conf/variables'
import legendDifficulty from "../assets/img/scheda_valutazione.61de7991.jpg";

function TipologiaTrek() {
    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Tipologia dei trek"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}>
                <div
                    className={"flex flex-row items-center gap-1"}
                    style={{ fontFamily: "bilo", fontWeight: 500, fontStyle: "normal" }}
                >
                    <div>I nostri trek  {">"}</div>
                    <div>Classificazione  {">"}</div>
                    <div className={"font-bold"}> Tipologia dei trek</div>
                </div>
                <InputGimlet
                    id='main'
                    title={'tipologia dei trek'}
                    fontWeight={500}
                    fontStyle="italic"
                    className={"my-6 uppercase text-4xl text-[#DF5B23]"}
                />
                <div className="mb-5 text-xl">
                    <InputBilo
                        title={'Oltre ai trek giornalieri vengono proposti trek di 2 o 3 giorni denominati WEEKEND o di 4 o più giorni denominati GRANDI TREK.'}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'TREK RESIDENZIALI'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={'Si tratta di escursioni, ogni giorno diverse, che hanno come base un solo albergo o rifugio. Basta portarecon sé uno zaino da escursione giornaliera (20-25 L). '}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'TREK ITINERANTI'}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={'Sono escursioni che prevedono ogni giorno una diversa tappa (albergo o rifugio). Bisogna quindi portare lo zaino con tutto l\'equipaggiamento richiesto per l\'intera durata del trek (35-40 L). '}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'TREK CON BAGAGLIO TRASPORTATO '}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={'Sono trek itineranti di più giorni, in cui il bagaglio (preferibilmente una borsa molle) dei partecipanti viene trasportato ad ogni posto tappa. È necessario avere e portare con sé uno zaino da escursione giornaliera (20-25 L) '}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'TREK CON RACCHETTE DA NEVE '}
                        fontWeight={700}
                    />
                    <InputBilo
                        title={'Per continuare a camminare anche d\'inverno! Un\'esperienza nella suggestiva cornice dell\'innevamento montano. '}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'Il socio deve essere dotato dell’attrezzatura tecnica necessaria (ciaspole, ghette, bastoncini, kit ARTVA dove richiesto) '}
                        fontWeight={300}
                    />
                    <br />
                    <InputBilo
                        title={'CONSIGLI PRIMA DI INTRAPRENDERE UN TREK'}
                        fontWeight={700}
                    />
                    <ul>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'assicurati di avere l’attrezzatura e abbigliamento richiesto '}
                                fontWeight={300}
                            />
                        </li>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'leggi attentamente la scheda del trek, controlla che la difficoltà complessiva ed eventuali difficoltà tecniche siano alla tua portata '}
                                fontWeight={300}
                            />
                        </li>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'chiedi informazioni all’accompagnatore, in caso di dubbi, attraverso il pulsante “Richiedi info” '}
                                fontWeight={300}
                            />
                        </li>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'allenati più volte e per alcuni giorni consecutivi per testare il tuo allenamento, facendo percorsi che prevedono del dislivello in salita; basta anche salire su è giù per le scale! '}
                                fontWeight={300}
                            />
                        </li>
                        <li className={'!list-disc ml-8'}>
                            <InputBilo
                                title={'controllala tua attrezzatura, in particolare gli scarponi o scarponcini e lo zaino. '}
                                fontWeight={300}
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </>
    )
}

export default TipologiaTrek